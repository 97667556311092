<script
    lang="ts"
    setup
>
    import type { AnyFn } from '~/ts/types/common'
    import type { AppDropdownProps } from '~/ts/types/app'
    import type { KBArticle, KBCategory, KBItem } from '~/ts/types/knowledge-base'
    import { KBItemTypeEnum } from '~/ts/enums/knowledge-base'
    import filterItems from '~/helpers/knowledge-base/filterItems'
    import findItem from '~/helpers/knowledge-base/findItem'
    import findItemAncestors from '~/helpers/knowledge-base/findItemAncestors'
    import flatTree from '~/helpers/knowledge-base/flatTree'

    type Props = {
        modelValue?: KBArticle['_id']
        items: KBItem[]
        withoutFooter?: boolean
        withCloseTrigger?: boolean
        disabled?: boolean
        dropdownArgs?: AppDropdownProps
        fieldSearchClass?: string
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
        (event: 'update:error', value: string): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const dropdownRef = ref<ReturnType<typeof defineComponent>>()
    const searchRef = ref<ReturnType<typeof defineComponent>>()
    const itemListRef = ref<HTMLDivElement>()

    onStartTyping(() => {
        if (searchRef.value && !searchRef.value.$refs.inputRef.disabled) {
            searchRef.value.$refs.inputRef.focus()
        }
    })

    const search = ref<string>('')

    const filteredItems = computed<KBItem[]>(() => {
        return filterItems(props.items, search.value)
    })

    const navigationItems = computed<KBItem[]>(() => {
        const items = []

        const handler = (nestedItems: KBItem[]) => {
            for (const index in nestedItems) {
                items.push(nestedItems[index])

                if (
                    (nestedItems[index].type === KBItemTypeEnum.Category)
                    && (search.value || openCategoryIds.value.includes(nestedItems[index]._id))
                ) {
                    const category = nestedItems[index] as KBCategory

                    handler(category.children)
                }
            }
        }

        handler(filteredItems.value)

        return items
    })

    const currentItem = ref<KBItem | undefined>()

    const currentItemIndex = computed<number | undefined>(() => {
        if (!currentItem.value) {
            return
        }

        return navigationItems.value.findIndex(tag => tag._id === currentItem.value._id)
    })

    const openCategoryIds = ref<string[]>([])

    if (props.modelValue) {
        const item = findItem(props.items, props.modelValue)

        if (item) {
            openCategoryIds.value = findItemAncestors(flatTree(props.items), item)
                .map(v => v._id)

            nextTick(() => {
                currentItem.value = item
            })
        }
    }

    const toggleCategory = (category: KBCategory): void => {
        if (openCategoryIds.value.includes(category._id)) {
            openCategoryIds.value = openCategoryIds.value.filter(v => v !== category._id)
        } else {
            openCategoryIds.value.push(category._id)
        }
    }

    const selectArticle = (article: KBArticle, close: AnyFn): void => {
        emit('update:modelValue', article._id)

        close()
    }

    const navigateToItem = (position: 'current' | 'next' | 'previous'): void => {
        if (!currentItem.value) {
            return
        }

        if (position === 'current') {
            if (currentItem.value.type === KBItemTypeEnum.Article) {
                selectArticle(currentItem.value as KBArticle, dropdownRef.value.close)
            } else {
                toggleCategory(currentItem.value as KBCategory)
            }

            return
        }

        let index = currentItemIndex.value

        if (position === 'next') {
            index++
        } else {
            index--
        }

        if (!navigationItems.value[index]) {
            return
        }

        currentItem.value = navigationItems.value[index]

        nextTick(() => {
            const itemEl = itemListRef.value.querySelector(`[data-id="${ currentItem.value._id }"]`)

            itemEl.scrollIntoView({
                block: 'nearest',
            })
        })
    }

    const onKeydownHandler = (event): void => {
        const run = (callback) => {
            event.preventDefault()

            callback()
        }

        switch (event.code) {
            case 'ArrowUp':
                return run(() => navigateToItem('previous'))
            case 'ArrowDown':
                return run(() => navigateToItem('next'))
            case 'Enter':
                return run(() => navigateToItem('current'))
        }
    }

    const onOpened = (): void => {
        if (searchRef.value) {
            searchRef.value.$refs.inputRef.focus()
        }

        window.addEventListener('keydown', onKeydownHandler, { passive: false })
    }

    const deactivateListeners = (): void => {
        window.removeEventListener('keydown', onKeydownHandler)
    }

    watch(() => props.items, () => {
        currentItem.value = filteredItems.value[0]
    }, { immediate: true })

    onUnmounted(() => deactivateListeners())
</script>

<template>
    <AppDropdown
        ref="dropdownRef"
        from-bottom
        min-width="624"
        without-padding
        :disabled="props.disabled"
        v-bind="props.dropdownArgs"
        @opened="onOpened()"
        @closed="deactivateListeners()"
    >
        <template #activator="dropdownSlotProps">
            <slot
                name="activator"
                v-bind="dropdownSlotProps"
            >
                <AppButtonIcon
                    v-slot="{ color }"
                    small
                    :active="dropdownSlotProps.active"
                    class="!mr-2"
                    @click="dropdownSlotProps.toggle()"
                >
                    <AppIconBooksThin
                        size="20"
                        :color="color"
                    />
                </AppButtonIcon>
            </slot>
        </template>

        <template #default="{ close }">
            <div class="flex flex-col chat-conversation-footer-input-controls-h">
                <div
                    v-if="currentItem && props.items.length"
                    key="filled"
                    class="flex flex-col h-full p-[16px_16px_0] max-tablet:pr-0"
                >
                    <div class="flex items-center gap-2">
                        <AppFormFieldSearch
                            key="search"
                            ref="searchRef"
                            v-model.trim="search"
                            name="search"
                            :placeholder="$t('search-articles-and-categories')"
                            :class="[ '!w-auto flex-1', props.fieldSearchClass ]"
                        />

                        <AppButtonIcon
                            v-if="props.withCloseTrigger"
                            key="button"
                            class="!hidden max-tablet:!flex mr-2"
                            small
                            @click="close()"
                        >
                            <AppIconClose size="20" />
                        </AppButtonIcon>
                    </div>

                    <div
                        ref="itemListRef"
                        class="overflow-auto mt-4 max-tablet:pr-4"
                    >
                        <AppFormFieldSelectKBItem
                            v-for="item in filteredItems"
                            :key="item._id"
                            :item="item"
                            :current-item="currentItem"
                            :force-open-category="!!search"
                            :open-category-ids="openCategoryIds"
                            @set-current-item="currentItem = $event"
                            @toggle-category="toggleCategory"
                            @select-article="emit('update:modelValue', $event._id); close()"
                        />
                    </div>
                </div>

                <div
                    v-else
                    key="empty"
                    class="flex flex-col items-center justify-center h-full p-[16px_16px_0]"
                >
                    <div class="flex items-center mb-4">
                        <!-- eslint-disable vue/no-v-html -->
                        <div
                            class="mr-2 text-[16px] text-center leading-[130%]"
                            v-html="$t('chat-knowledge-base-empty-state-text')"
                        ></div>
                        <!-- eslint-enable vue/no-v-html -->
                    </div>

                    <AppLink
                        wrapper
                        :to="{ name: 'p-pid-knowledge-base' }"
                    >
                        <AppButton>
                            {{ $t('go-to-knowledge-base') }}
                        </AppButton>
                    </AppLink>
                </div>

                <ChatConversationFooterInputControlsModalFooter
                    v-if="!props.withoutFooter"
                    key="footer"
                >
                    <template #move-text>
                        {{ $t('moving') }}
                    </template>
                </ChatConversationFooterInputControlsModalFooter>
            </div>
        </template>
    </AppDropdown>
</template>
